<template>
    <div class="display3d">
        <div id="map" />
        <TopShareBar />
    </div>
</template>

<script>

import mapboxgl from "mapbox-gl";
import TopShareBar from '@/components/TopShareBar';

export default {
    name: "Display3D",
    components: {TopShareBar},
    data(){
        return{
            map: '',
        }
    },
    methods: {
        init() {
            mapboxgl.accessToken = 'pk.eyJ1Ijoid3l6d2FuZ3lhemhvdSIsImEiOiJjbGc0Z3RiN3Qwa3lhM3JzYWFmdGdsaWtvIn0.g8f6kkGmPIkD1mXGRO9Q2g';
            this.map = new mapboxgl.Map({
                container: 'map',
                center: [0, 0],
                zoom: 18,
                // style: 'mapbox://styles/tandaqi/cknjpe5111hb817padzh2oz0w',
              style: 'mapbox://styles/wyzwangyazhou/clg4hq3vu000u01nsbyk98k7i',
                attributionControl: false,
                interactive: false,
                transformRequest: (url) => {
                    if (url.indexOf('https://api.mapbox.com') > -1) {
                        return {
                            url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
                        };
                    }
                }
            });
            this.map.on('load', () => {
                this.map.addSource('mapbox-dem', {
                    type: 'raster-dem',
                    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    tileSize: 512,
                    maxZoom: 14,
                });
                this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
                this.map.addLayer({
                    id: 'sky',
                    type: 'sky',
                    paint: {
                        'sky-type': 'atmosphere',
                        'sky-atmosphere-sun': [0.0, 0.0],
                        'sky-atmosphere-sun-intensity': 15,
                    }
                });
                this.load()
            });
        },
        load() {
            let data = new FormData()
            data.append('shareType', '1')
            data.append('infoId', this.$route.query.infoId)
            this.$http.post('/earthApi/main-info/getThreeDInfoDetail', data).then(response => {
                if(response.data.status === 200){
                    this.map.addSource('china1m2021', {
                        type: 'raster',
                        tiles: [
                            'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf'
                        ],
                        tileSize: 256,
                        scheme: 'tms',
                    });
                    this.map.addLayer({
                        id: 'china1mLayer',
                        type: 'raster',
                        source: 'china1m2021',
                    });
                    let _info = response.data.data
                    if(_info['cite'] != ''){
                        this.map.addSource('3DCiteLayer',{
                            type: 'raster',
                            tiles: [
                                _info['cite'],
                            ],
                            tileSize: 256,
                            scheme: 'tms'
                        });
                        this.map.addLayer({
                            'id': '3DCiteLayer',
                            'type': 'raster',
                            'source': '3DCiteLayer',
                        });
                    }
                    this.map.jumpTo({center: [parseFloat(_info['lon']), parseFloat(_info['lat'])], zoom: parseFloat(_info['zoom']), pitch: parseFloat(_info['angle'])})
                    this.rotateCamera(0)
                    document.title = String(_info['title'])
                } else {
                    this.$router.push({path: '/NotExist'})
                }
            })
        },
        rotateCamera(timestamp) {
            this.map.rotateTo((timestamp / 100) % 360, { duration: 0 })
            requestAnimationFrame(this.rotateCamera)
        },
    },
    mounted() {
        window.mapboxgl = this;
        if(this.$route.query.infoId != null){
            if(this.$route.query.title != null){
                document.getElementsByClassName("display3d")[0].style.backgroundImage = 'url(' + this.$route.query.gif + ')';
                document.title = String(this.$route.query.title)
            } else {
                this.init();
            }
        }
    }
}
</script>

<style scoped lang="scss">

.display3d{
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    position: absolute;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

</style>
